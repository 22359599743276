import getTailwindConfig from './get-tailwind-config';

export type MobileBreakpoint = 'sm' | 'md' | 'lg';

const screens = getTailwindConfig('theme.screens') as Record<MobileBreakpoint, string>;

function getMobileBreakpoint(breakpoint: MobileBreakpoint): string {
  return screens[breakpoint];
}

export default function checkMobileView(breakpoint: MobileBreakpoint = 'lg'): boolean {
  if (typeof window !== 'undefined') {
    return window?.innerWidth < parseInt(getMobileBreakpoint(breakpoint), 10);
  }
  return false;
}
