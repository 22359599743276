import Cookies from 'js-cookie';
import { useMemo } from 'react';

import { useCommerce } from '@commerce';
import { SWRHook } from '@commerce/utils/types';
import { useSWRHook } from '@commerce/utils/use-hook';
import { getStorageKey } from '@components/checkout/helpers';
import { addQueryString } from '@framework/lib/add-query-string';
import type { GetCartHook } from '@framework/types/cart';
import { removeStorage } from '@lib/browser-storage';

const clearBrowserStorage = (locale: string) => {
  // if cart is empty due to invalid ID (expired, deleted, not found)
  // clear checkout data in browser storage
  const metadataKey = getStorageKey('metadata', locale);
  const checkoutKey = getStorageKey('userInfo', locale);
  removeStorage(checkoutKey);
  removeStorage(metadataKey);
};

export const handler: SWRHook<GetCartHook> = {
  fetchOptions: {
    url: '/api/cart',
    method: 'GET',
  },
  async fetcher({ input: { locale, queryCartId }, options, fetch }) {
    const url = addQueryString(options.url!, { locale, ...(queryCartId && { queryCartId }) });
    try {
      const data = await fetch({ ...options, url: url.pathname + url.search });

      if (!data && locale) {
        clearBrowserStorage(locale);
      }

      return data;
    } catch (err: any) {
      if (err?.status === 404) {
        // cart ID not set on cookies or invalid
        if (locale) {
          clearBrowserStorage(locale);
        }
        // return empty data back
        return null;
      }

      throw err;
    }
  },
  useHook: ({ useData }) =>
    function useCustomHook(input) {
      const response = useData({
        input: [['locale', useCommerce().locale]],
        // !!important always set revalidateOnFocus to false, as it mess up rendering (especially on checkout payment processing)
        swrOptions: { revalidateOnFocus: false, ...input?.swrOptions },
      });

      return useMemo(
        () =>
          Object.create(response, {
            isEmpty: {
              get() {
                return (response.data?.lineItems?.length ?? 0) <= 0;
              },
              enumerable: true,
            },
          }),
        [response]
      );
    },
};

export type UseCart<H extends SWRHook<GetCartHook> = SWRHook<GetCartHook>> = ReturnType<H['useHook']>;

const useCart: UseCart = (input) => {
  const { cartCookie } = useCommerce();
  const wrapper: typeof handler.fetcher = (context) => {
    context.input.cartId = Cookies.get(cartCookie);
    context.input.queryCartId = input?.cartId;
    return handler.fetcher(context);
  };
  return useSWRHook({ ...handler, fetcher: wrapper })(input);
};

export default useCart as UseCart<typeof handler>;
