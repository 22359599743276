import Link from 'next/link';
import { useRouter } from 'next/router';

import { Container } from '@components/ui';
import { renderImage } from '@lib/image';
import { FCWithChildren } from '@lib/types/react-utilities';

import s from './CompactNavbar.module.scss';

export const CompactNavbar: FCWithChildren = ({ children }) => {
  const { query } = useRouter();

  return (
    <header className={s.header}>
      <Container className={s.headerWrapper} mobilePadding>
        <Link href="/" title="home" style={query?.inapp ? { pointerEvents: 'none' } : undefined} className="max-h-full">
          {renderImage({ url: '/svgs/life360-tile-logo-mobile.svg' }, { 'aria-hidden': true, className: s.logo })}
        </Link>

        {children}
      </Container>
    </header>
  );
};
