import cn from 'classnames';
import { Trans, useTranslation } from 'next-i18next';

import { Text } from '@components/ui';

import style from './CartHeader.module.scss';

export default function CartHeader({
  className,
  itemCount,
  subtotal,
  showSubtotal = true,
  headerClassName,
  textClassName,
}: {
  className?: string;
  itemCount: number;
  subtotal: string;
  showSubtotal?: boolean;
  headerClassName?: string;
  textClassName?: string;
}) {
  const { t } = useTranslation('cart');

  return (
    <h2 className={cn(style.wrapper, className)}>
      <Text className={headerClassName} variant="header-4" asElement="span" color="var(--text-primary)">
        {t('cart:header.cart')}
      </Text>
      <span className={style.contentWrapper}>
        <Text variant="base" className={textClassName} asElement="span" color="var(--text-primary)">
          <Trans i18nKey="cart:header.totalItem" values={{ count: itemCount }} />
        </Text>
        {!!subtotal && showSubtotal && (
          <Text variant="base" className={textClassName} asElement="span" color="var(--text-primary)">
            {subtotal}
          </Text>
        )}
      </span>
    </h2>
  );
}
