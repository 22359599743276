import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode, useEffect, useState } from 'react';

import style from './Accordion.module.scss';

interface Props {
  header: string | ReactNode;
  separator?: boolean;
  collapsed?: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  className?: string;
  children: ReactNode;
}

const Accordion: FC<Props> = ({
  header,
  separator = false,
  className,
  children,
  collapsed: defaultCollapsed = false,
  setCollapsed: defaultSetCollapsed,
}) => {
  const [localCollapsed, setLocalCollapsed] = useState(defaultCollapsed);
  const { t } = useTranslation('product');

  useEffect(() => {
    setLocalCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  const { collapsed, setCollapsed } = defaultSetCollapsed
    ? { collapsed: defaultCollapsed, setCollapsed: defaultSetCollapsed }
    : { collapsed: localCollapsed, setCollapsed: setLocalCollapsed };

  // currently just re-using fade-in animation, TBD on animation/transition from designer
  const contentStyle = cn(
    'animated',
    {
      'fade-in': !collapsed,
      hidden: collapsed,
    },
    style.content
  );

  const renderHeader = () => {
    if (typeof header === 'string') {
      return <span>{header}</span>;
    }
    return header;
  };

  return (
    <div className={`${style.container} ${className || ''}`} data-testid="accordion">
      <button className={style.header} type="button" onClick={() => setCollapsed(!collapsed)}>
        {renderHeader()}
        <FontAwesomeIcon
          className="text-secondary"
          icon={['fal', !collapsed ? 'chevron-up' : 'chevron-down']}
          size="sm"
          title={t(`filter.${!collapsed ? 'collapse' : 'expand'}`)}
          aria-hidden
          focusable={false}
        />
      </button>
      {separator && <hr className={style.separator} />}
      <div className={contentStyle} data-testid="accordion-content">
        {children}
      </div>
    </div>
  );
};

export default Accordion;
