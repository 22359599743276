import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Portal } from '@reach/portal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import ClickOutside from '@lib/click-outside/click-outside';
import FocusTrap from '@lib/focus-trap';

import s from './Modal.module.scss';

interface Props {
  className?: string;
  rootClassName?: string;
  contentClassName?: string;
  children?: any;
  open?: boolean;
  onClose: () => void;
  hideCloseBtn?: boolean;
  closeOnClickOutside?: boolean;
}

export interface ModalView {
  closeModal?: () => void;
}

const Modal: FC<Props> = ({
  className,
  rootClassName,
  contentClassName,
  children,
  open,
  onClose,
  hideCloseBtn = false,
  closeOnClickOutside = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return onClose();
      }

      return null;
    },
    [onClose]
  );

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current);
        window.addEventListener('keydown', handleKey);
      } else {
        enableBodyScroll(ref.current);
      }
    }
    const bodyRef = ref.current;
    return () => {
      if (bodyRef) {
        enableBodyScroll(bodyRef);
      }
      window.removeEventListener('keydown', handleKey);
    };
  }, [open, handleKey]);

  const handleOnClose = () => {
    if (ref.current) {
      enableBodyScroll(ref.current);
    }
    onClose();
  };

  const renderModal = (scrollRef?: RefObject<HTMLDivElement>) => (
    <div className={cn(s.modal, className)} role="dialog">
      {!hideCloseBtn && (
        <button
          type="button"
          onClick={handleOnClose}
          aria-label="Close panel"
          className="hover:text-gray-500 transition ease-in-out duration-150 focus:outline-none absolute right-0 top-0 m-6"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
      )}
      <div className={cn(s.content, contentClassName)} ref={scrollRef}>
        <FocusTrap focusFirst>
          {Children.map(children, (child) => {
            if (isValidElement(child)) {
              return cloneElement(child as ReactElement<any>, { closeModal: handleOnClose });
            }
            return child;
          })}
        </FocusTrap>
      </div>
    </div>
  );

  return (
    <Portal>
      {open ? (
        <div className={cn(s.root, rootClassName)}>
          {closeOnClickOutside ? (
            <ClickOutside active={open} onClick={() => onClose()}>
              {renderModal(ref)}
            </ClickOutside>
          ) : (
            renderModal()
          )}
        </div>
      ) : null}
    </Portal>
  );
};

export default Modal;
