import cn from 'classnames';
import React, { FC, forwardRef, Ref } from 'react';

interface Props {
  className?: string;
  children?: any;
  asElement?: React.ComponentType<any> | string;
  ref?: Ref<any>;
  mobilePadding?: boolean;
  oversized?: boolean;
}

const Container: FC<Props> = forwardRef(
  ({ children, className, asElement = 'div', mobilePadding = false, oversized = false }, ref) => {
    const rootClassName = cn(
      'container',
      { 'px-8 lg:px-0': mobilePadding },
      {
        'container--oversized': oversized,
      },
      className
    );

    const Component = asElement;

    return (
      <Component ref={ref} className={rootClassName}>
        {children}
      </Component>
    );
  }
);

Container.displayName = 'Container';
export default Container;
