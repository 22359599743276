import { z } from 'zod';

import { ErrorWithCode } from './errors/ErrorWithCode';

const ApiErrorSchema = z.object({
  errors: z.array(
    z.object({
      code: z.string(),
    })
  ),
});

export default function getErrorCode(err: unknown, fallback = 'default'): string {
  if (err instanceof ErrorWithCode) {
    return err.code ?? fallback;
  }
  const apiErrorParsed = ApiErrorSchema.safeParse(err);
  if (apiErrorParsed.success) {
    return apiErrorParsed.data.errors[0]?.code ?? fallback;
  }
  return fallback;
}
