import { useMemo } from 'react';

import { useCommerce } from '@commerce';

export const defaultCurrency = 'USD';

export function formatPrice({
  amount,
  currencyCode,
  locale,
  decimals = 2,
}: {
  amount: number;
  currencyCode: string;
  locale: string;
  decimals?: number;
}) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: currencyCode,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  const formattedCurrency = formatCurrency.format(amount);
  switch (currencyCode) {
    case 'AUD':
      return `A${formattedCurrency}`;
    case 'NZD':
      return `NZ${formattedCurrency}`;
    case 'CAD':
      return `C${formattedCurrency}`;
    default:
      return formattedCurrency;
  }
}

export default function usePrice(
  data?: {
    amount: number;
    currencyCode?: string;
    decimals?: number;
  } | null
) {
  const { amount, currencyCode = defaultCurrency, decimals } = data ?? {};
  const { locale } = useCommerce();
  const value = useMemo(() => {
    if (typeof amount !== 'number' || Number.isNaN(amount) || !currencyCode) {
      return '';
    }
    return formatPrice({ amount, currencyCode, locale, decimals });
  }, [amount, currencyCode, decimals, locale]);

  return { price: value };
}
