import { CommerceError } from './CommerceError';
import { ErrorProps } from './types';

// Used for errors that come from a bad implementation of the hooks
export class ValidationError extends CommerceError {
  constructor(options: ErrorProps) {
    super(options);
    this.code = 'validation_error';
  }
}
