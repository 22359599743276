import { useCallback } from 'react';

import { useCommerce } from '@commerce';
import type { LineItem, UpdateItemHook } from '@commerce/types/cart';
import { ValidationError } from '@commerce/utils/errors';
import type { HookFetcherContext, MutationHook, MutationHookContext } from '@commerce/utils/types';
import { useMutationHook } from '@commerce/utils/use-hook';
import { addQueryString } from '@framework/lib/add-query-string';
import { trackEvent, trackGA4UpdateCartEvent } from '@lib/gtag';

import useCart from './use-cart';
import { handler as removeItemHandler } from './use-remove-item';

export type UpdateItemActionInput<T = any> = T extends LineItem
  ? Partial<UpdateItemHook['actionInput']>
  : UpdateItemHook['actionInput'];

export const handler = {
  fetchOptions: {
    url: '/api/cart',
    method: 'PUT',
  },
  async fetcher({ input: { itemId, item, locale }, options, fetch }: HookFetcherContext<UpdateItemHook>) {
    if (Number.isInteger(item.quantity)) {
      // Also allow the update hook to remove an item if the quantity is lower than 1
      if (item.quantity! < 1) {
        return removeItemHandler.fetcher({
          options: removeItemHandler.fetchOptions,
          input: { itemId, locale },
          fetch,
        });
      }
    } else {
      throw new ValidationError({
        message: 'The item quantity has to be a valid integer',
      });
    }

    const url = addQueryString(options.url!, { locale });

    try {
      const data = await fetch({
        ...options,
        url: url.pathname + url.search,
        body: { itemId, item },
      });
      return data;
    } catch (err: any) {
      if (err?.status === 404) {
        // returns empty data
        // cart mutate will refetch/reload the cart
        return null;
      }

      throw err;
    }
  },
  useHook: ({ fetch }: MutationHookContext<UpdateItemHook>) =>
    function useCustomHook<T extends LineItem | undefined = undefined>(
      ctx: {
        item?: T;
        wait?: number;
      } = {}
    ) {
      const { item } = ctx;
      const { mutate } = useCart() as any;
      const { locale } = useCommerce();

      return useCallback(
        async (input: UpdateItemActionInput<T>) => {
          const { id: itemId = item?.id, productId = item?.productId } = input;

          if (!itemId || !productId) {
            throw new ValidationError({
              message: 'Invalid input used for this operation',
            });
          }

          const data = await fetch({
            input: {
              itemId,
              item: { productId, quantity: input.quantity },
              locale,
            },
          });

          trackGA4UpdateCartEvent(item?.productId, data, item?.quantity);

          trackEvent({
            action: `Changed Item Quantity ${input.quantity}`,
            category: 'Cart',
            label: item?.variant?.sku,
          });

          await mutate(data, false);
          return data;
        },
        [item?.id, item?.productId, locale, mutate, item?.variant?.sku, item?.quantity]
      );
    },
};

export type UseUpdateItem<H extends MutationHook<UpdateItemHook<any>> = MutationHook<UpdateItemHook>> = ReturnType<
  H['useHook']
>;

const useUpdateItem: UseUpdateItem = (input) => {
  return useMutationHook({ ...handler })(input);
};

export default useUpdateItem as UseUpdateItem<typeof handler>;
