export class ErrorWithCode extends Error {
  code?: string;

  constructor(message?: string, code?: string) {
    super(message);
    if (code) {
      this.code = code;
    }
  }
}
